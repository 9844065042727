import PublicAirdropAbi from "../config/abi/PublicAirdropAbi.json";
import Web3 from "web3";

export async function getAirdropIsCancelled(airdropAddress) {
  const web3 = new Web3(window.ethereum);
  await window.ethereum.enable();

  const contract = new web3.eth.Contract(PublicAirdropAbi, airdropAddress);

  try {
    const result = await contract.methods.airdropCancelled().call();
    return result;
  } catch (error) {
    return error;
  }
}

export async function getAirdropIsEmpty(airdropAddress) {
  const web3 = new Web3(window.ethereum);
  await window.ethereum.enable();
  const contract = new web3.eth.Contract(PublicAirdropAbi, airdropAddress);

  try {
    const result = await contract.methods.airdropEmpty().call();
    return result;
  } catch (error) {
    return error;
  }
}

export async function getAirdropIsStarted(airdropAddress) {
  const web3 = new Web3(window.ethereum);
  await window.ethereum.enable();

  const contract = new web3.eth.Contract(PublicAirdropAbi, airdropAddress);

  try {
    const result = await contract.methods.airDropStarted().call();
    return result;
  } catch (error) {
    return error;
  }
}
