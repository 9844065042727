export const RbaChain = {
  chainId: 158,
  chainName: 'RBA',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0x6eFEC35906F015b704C29EECE2E403fD6A587743',
  getExplorerAddressLink: (address) => `https://rbascan.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash) => `https://rbascan.com/tx/${transactionHash}`,
  rpcUrl: 'https://dataseed.roburna.com',
  blockExplorerUrl: 'https://rbascan.com/',
  nativeCurrency: {
    name: 'Roburna',
    symbol: 'RBA',
    decimals: 18,
  },
}

