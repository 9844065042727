export const wallets = [
  {
    id: 1,
    name: 'Metamask',
    image: '/images/topbar/wallets/metamask.svg',
  },
  {
    id: 2,
    name: 'WalletConnect',
    image: '/images/topbar/wallets/wallet_connect.svg',
  },
  {
    id:3,
    name :"Trust Wallet",
  image:"/images/topbar/wallets/trustwallet.svg"
  }
]
