const BACKEND_URL = "https://protocol-api.arborswap.org";
const BACKEND_URL_REF = "https://api.arborpad.io";

const FairLaunch_FACTORYADRESS = "0xAcBC8c09595a4658953C396a4A69F50fc0363e25";
const FairLaunchErc_FACTORYADRESS =
  "0x4cf7d10c5b331aC86Bbdd4dbaE6bda8be2df48d7";

const Private_FACTORYADRESS = "0x9e97e1EeA456DB916Aa370F9663304959cd1031A";
const PrivateErc_FACTORYADRESS = "0xD6218eC2fbAadee7653ecA764f0acfF14f247893";

//used
const PublicErc_FACTORYADRESS = "0xbDce240195Ce30C126400070d1351c0E8A34Ea1B";
const Public_FACTORYADRESS = "0x42337811F1E268B3eF1Eb63b0f2a9F70bbE4b9FD";
const Curated_FACTORYADRESS = "0xc1db6085eE08017e95C14125dFdD0FCAb95CC7Cc";
const CuratedErc_FACTORYADRESS = "0x6c04005363A4E012970484B0cbc910bF6EEEa087";

//mainnet
const BSC_PUBLIC_FACTORYADDRESS = "0xD1876A2d32e3a35ad189621E44058D4FD80A322C";
const BSC_PUBLIC_ERC_FACTORYADDRESS =
  "0x1D7a2C1F64Ce432e969a09832B878444de45A805";
const RBA_CURATED_ERC_FACTORYADDRESS =
  "0x29db8B9a8171E6E7Aea708F307c20fFdd452d1c7";
const RBA_CURATED_FACTORYADDRESS = "0x699cC9e5f4069Bd6750d6a86Ae3df225e316BE7F";
const RBA_PUBLIC_FACTORYADDRESS = "0xB531e147F24D6b18BC1cC90c79264E472277Bc09";
const RBA_PUBLIC_ERC_FACTORYADDRESS =
  "0xA052b475eD142f80EccC029F48018CC41961b40c";
const RBA_PRIVATE_FACTORYADDRESS = "0x4786c8A1D171c01adae5659139cDbFBCDBb28267";
const RBA_PRIVATE_ERC_FACTORYADDRESS =
  "0x3BF42cf126d01EdFfF33BE3Ea63EE1B1616fE449";

const LOCK_ADDRESS = "0x2E057297C7658a84eFA3f08413C1Fcd638d3a7c4";

const CHAIN_ID = 97;
const RBA_ROUTER_ADDRESS = "0x5ee8faea07caae4e344ef2d5bfaa0c2dcf51ea9f";
const ROUTER_ADDRESS = "0x9ac64cc6e4415144c455bd8e4837fea55603e5c3";
const BSC_ROUTER_ADDRESS = "0x10ED43C718714eb63d5aA57B78B54704E256024E";
const ADMIN_ADDRESS = "0x5317a31c1ded3f7e86e6c97578507e679cf7b734";
const ADMIN_ADDRESS_CURATED = "0x86b438716263bF04a0B77Aa6527e383AC95adB1C";
const ADMIN_ADDRESS_RBA = "0xEa2EE9e8772c2f40D6318db389af8ED70140d507";

const USDT_ADDRESS = "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd";
const RBA_ADDRESS = "0x9CB10F82de652C480a45761447F82041572d244c";
const USDC_ADDRESS = "0xc22250887C9626A455F8F4ee84eAc51fE6ff1F3d";

const USDT_ADDRESS_BSC = "0x55d398326f99059fF775485246999027B3197955";
const USDC_ADDRESS_BSC = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";
const RBA_ADDRESS_BSC = "0xdd534480782ecf53e4a5257b0f3c37702a0bad61";

const USDC_ADDRESS_RBA = "0x21F2fb5De15Ab4f2101023698355D27403bdB543";
const USDT_ADDRESS_RBA = "0xF183CbD16ca8C4ebc5c51908Fd92EbB89De687C7";

export {
  FairLaunch_FACTORYADRESS,
  Private_FACTORYADRESS,
  Public_FACTORYADRESS,
  CHAIN_ID,
  FairLaunchErc_FACTORYADRESS,
  PrivateErc_FACTORYADRESS,
  PublicErc_FACTORYADRESS,
  ROUTER_ADDRESS,
  ADMIN_ADDRESS,
  BACKEND_URL,
  USDT_ADDRESS,
  RBA_ADDRESS,
  USDC_ADDRESS,
  LOCK_ADDRESS,
  BSC_PUBLIC_FACTORYADDRESS,
  BSC_ROUTER_ADDRESS,
  BSC_PUBLIC_ERC_FACTORYADDRESS,
  USDT_ADDRESS_BSC,
  USDC_ADDRESS_BSC,
  RBA_ADDRESS_BSC,
  BACKEND_URL_REF,
  Curated_FACTORYADRESS,
  CuratedErc_FACTORYADRESS,
  ADMIN_ADDRESS_CURATED,
  RBA_ROUTER_ADDRESS,
  RBA_CURATED_FACTORYADDRESS,
  RBA_CURATED_ERC_FACTORYADDRESS,
  ADMIN_ADDRESS_RBA,
  USDC_ADDRESS_RBA,
  USDT_ADDRESS_RBA,
  RBA_PUBLIC_FACTORYADDRESS,
  RBA_PUBLIC_ERC_FACTORYADDRESS,
  RBA_PRIVATE_FACTORYADDRESS,
  RBA_PRIVATE_ERC_FACTORYADDRESS,
};
