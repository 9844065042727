import PublicSaleAbi from "../config/abi/PublicSale.json";

import Web3 from "web3";

//public
async function getParticipated(saleAddress,account) {
  try{
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    const contract = new web3.eth.Contract(PublicSaleAbi, saleAddress);
    const participated = await contract.methods.isParticipated(account).call();
    console.log(participated,"returning")
    return participated;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export default getParticipated;
