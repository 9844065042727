import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import PoolPageBase from "../../components/Launchpad/PoolPage";
import { useEthers } from "@usedapp/core";
import Modal from "components/Launchpad/Modal";
import axios from "axios";
import { useModal } from "react-simple-modal-provider";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import Web3 from "web3";
import { Contract } from "ethers";
import PublicSaleAbi from "../../config/abi/PublicSale.json";
import PublicSaleErcAbi from "../../config/abi/PublicSaleErcAbi.json";
import getSaleInfo from "utils/getSaleInfo";
import { BigNumber } from "ethers";
import { formatBigToNum } from "utils/numberFormat";
import { useDefaultChainId } from "config/useDefaultChainId";
import { useNetwork } from "context/networkContxt";

export default function PoolPage() {
  const { id } = useParams();
  // get refferalId if exists
  const { referralId } = useParams();
  const { account, library, switchNetwork } = useEthers();
  const [pool, setPool] = useState(null);
  const [modal, showModal] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [adminMode, setAdminMode] = useState(false);
  const [userAccount, setUserAccount] = useState(null);
  const [saleOwner, setSaleOwner] = useState(null);
  const { updateActiveItem,updateItemData,itemData } = useNetwork();
  const chainId = useDefaultChainId();
  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");

  useEffect(() => {
    async function getAccount() {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.enable();
        const res = await web3.eth.getAccounts();
        setUserAccount(res[0]);
        if (res[0] === saleOwner) {
          setAdmin(true);
          setAdminMode(true);
        } else {
          setAdmin(false);
          setAdminMode(false);
        }
      } catch (e) {
        console.log(e);
      }
    }
    getAccount();
  }, [saleOwner, account]);

  useEffect(() => {
    //get pool data from api
    openLoadingModal();
    axios
      .get(`${BACKEND_URL}/api/sale/${id}`)
      .then((res) => {
        setPool(res.data);
        document.title = res.data.sale.name;
        // Check if the user is admin
        setSaleOwner(res.data.sale.owner);
        closeLoadingModal();
      })
      .catch((err) => {
        console.log(err);
        //alert ("Something went wrong")
        closeLoadingModal();
      });
  }, []);

  // if referralId exists, then store that and saleid in cookies
  useEffect(() => {
    if (referralId && pool) {
      document.cookie = "referralId=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      document.cookie = "saleId=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";

      document.cookie = `referralId=${referralId};`;
      document.cookie = `saleId=${pool.sale.saleAddress};`;
    }
  }, [referralId, pool]);

  useEffect(() => {
    const switchNetworkIfNeeded = async () => {
      if (pool && chainId !== pool.sale.chainID) {
        try {
          console.log("Switching network to", pool.sale.chainID);
          await switchNetwork(pool.sale.chainID);
          let temItem = [...itemData]
          temItem = temItem.map((item) => {
                        return {...item, isActive: false, status: false, subtitle: "Not Connected",  }
          })
          if (pool.sale.chainID === 97) {
            updateActiveItem({
              img: "/images/headericons/BinanceSmartChainBadge.svg",
              title: "BSC Testnet",
            });
            temItem.find (item => item.title === "BSC Testnet").isActive = true
            temItem.find (item => item.title === "BSC Testnet").status = true
            temItem.find (item => item.title === "BSC Testnet").subtitle = "Connected"
          } else if (pool.sale.chainID === 56) {
            updateActiveItem({
              img: "/images/headericons/BinanceSmartChainBadge.svg",
              title: "Binance Smart Chain",
            });
            temItem.find (item => item.title === "Binance Smart Chain").isActive = true
            temItem.find (item => item.title === "Binance Smart Chain").status = true
            temItem.find (item => item.title === "Binance Smart Chain").subtitle = "Connected"
          } else if (pool.sale.chainID === 158) {
            updateActiveItem({
              img: "/images/logo-small.svg",
              title: "Roburna",
            });
            temItem.find (item => item.title === "Roburna").isActive = true
            temItem.find (item => item.title === "Roburna").status = true
            temItem.find (item => item.title === "Roburna").subtitle = "Connected"
          }
          updateItemData(temItem)
        } catch (error) {
          console.error("Error switching network:", error);
          // Handle error appropriately, e.g., show a message to the user
        }
      }
    };

    switchNetworkIfNeeded();
  }, [pool, chainId]);

  return (
    pool && (
      <div className="w-full">
        {modal && (
          <div className="fixed z-50  top-0 left-0">
            <Modal
              showModal={showModal}
              from_symbol={pool.sale.currency.symbol}
              from_icon={pool.sale.currency.icon}
              to_icon={pool.sale.token.image}
              to_symbol={pool.sale.token.tokenSymbol}
              token={pool.sale.token}
              sale={pool.sale}
              objId={pool._id}
              account={userAccount}
            />
          </div>
        )}
        <BaseLayout
          page_name={"Pools"}
          title={pool.sale.name}
          subpage
          admin={admin}
          setAdminMode={setAdminMode}
        >
          <PoolPageBase
            objId={pool._id}
            pool={pool.sale}
            visible={pool.visible}
            showModal={showModal}
            admin={adminMode}
            isFinished={pool.isFinished}
            isCancelled={pool.isCancelled}
          />
        </BaseLayout>
      </div>
    )
  );
}
