// context/networkContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { Networkswitch } from 'data/networkswitch'; // Adjust import as per your project structure

const NetworkContext = createContext();

export const useNetwork = () => useContext(NetworkContext);

export const NetworkProvider = ({ children }) => {
  const [activeItem, setActiveItem] = useState({
    img: '/images/headericons/BinanceSmartChainBadge.svg',
    title: 'Binance Smart Chain'
  });
  const [itemData, setItemData] = useState([]);

  useEffect(() => {
    const nSwitch = window.localStorage.getItem('network-switch');
    if (nSwitch) {
      setItemData(JSON.parse(nSwitch));
    } else {
      setItemData(Networkswitch); // Set default network data
    }
  }, []);

  const updateActiveItem = (newActiveItem) => {
    setActiveItem(newActiveItem);
  };

  const updateItemData = (newItemData) => {
    setItemData(newItemData);
    localStorage.setItem('network-switch', JSON.stringify(newItemData));
    window.dispatchEvent(new Event('storage'));
  };

  const value = {
    activeItem,
    updateActiveItem,
    itemData,
    updateItemData
  };

  return <NetworkContext.Provider value={value}>{children}</NetworkContext.Provider>;
};
