import React, { useState, useEffect } from "react";
import {
  getAirdropList,
  getAirdropInfos,
  sortAirdrops,
  getPublicAirdrops,
} from "utils/getAirdropList";
import { useDefaultChainId } from "config/useDefaultChainId";

import AirdropsBase from "../../components/Airdropper/Airdrops";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import HomeLayout from "../../components/HomeLayout";
import { useDocumentTitle } from "../../hooks/setDocumentTitle";
import AirplaneSVG from "../../svgs/Sidebar/airplane";
import { useModal } from "react-simple-modal-provider";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import axios from "axios";
const Tabs = [
  {
    id: 1,
    tabName: "Live",
  },
  {
    id: 2,
    tabName: "Upcoming",
  },
  {
    id: 3,
    tabName: "Ended",
  },
];

export default function Airdrops() {
  useDocumentTitle("Airdrops");

  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");
  const [ready, setReady] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [endedList, setEndedList] = useState([]);
  const [timedList, setTimedList] = useState([]);
  const [liveList, setLiveList] = useState([]);
  const [publicList, setPublicList] = useState([]);
  const [filteredAirdrops, setFilteredAirdrops] = useState([]);
  const chainId = useDefaultChainId();
  const getAirdropListFromBackend = async () => {
    const response = await axios.get(`${BACKEND_URL}/api/airdrop/`, {
      params: {
        chainId: chainId,
      },
    });
    console.log(response, "response");
    return response;
  };
  const handleFetch = async () => {
    setReady(false);
    openLoadingModal();
    try {
      const airdrops = await getAirdropListFromBackend();
      if (airdrops && chainId) {
        console.log(airdrops.data, "airdrops.data");
        const arrayOfAddresses = airdrops.data.map(
          (airdrop) => airdrop.airdrop.airdropAddress
        );
        const sortedAirdrops = await sortAirdrops(chainId, arrayOfAddresses);
        let timed = sortedAirdrops.data.timed;
        let live = sortedAirdrops.data.live;
        let ended = sortedAirdrops.data.ended;
        // we will match addresses to airdrops.data.airdropAddress and store in respective state
        let timedList = [];
        let liveList = [];
        let endedList = [];

        for (let i = 0; i < timed.length; i++) {
          const airdrop = airdrops.data.find(
            (airdrop) => airdrop.airdrop.airdropAddress === timed[i]
          );
          if (airdrop) {
            timedList.push(airdrop);
          }
        }
        for (let i = 0; i < live.length; i++) {
          const airdrop = airdrops.data.find(
            (airdrop) => airdrop.airdrop.airdropAddress === live[i]
          );
          if (airdrop) {
            liveList.push(airdrop);
          }
        }
        for (let i = 0; i < ended.length; i++) {
          const airdrop = airdrops.data.find(
            (airdrop) => airdrop.airdrop.airdropAddress === ended[i]
          );
          if (airdrop) {
            endedList.push(airdrop);
          }
        }

        setTimedList(timedList);
        setLiveList(liveList);
        setEndedList(endedList);
      }
      closeLoadingModal();
      setReady(true);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(
    timedList,
    "timedList",
    liveList,
    "liveList",
    endedList,
    "endedList"
  );

  useEffect(() => {
    if (chainId) handleFetch();
  }, [chainId]);

  return (
    <BaseLayout
      title={"Airdropper"}
      title_img={<AirplaneSVG className="md:hidden fill-dim-text" />}
      page_name={"Airdrops"}
      page_description={"Airdrop to multiple users in few clicks."}
    >
      <HomeLayout
        airdrop
        tabs={Tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      >
        {ready ? (
          <AirdropsBase
            timedList={timedList}
            liveList={liveList}
            endedList={endedList}
            publicList={publicList}
            activeTab={activeTab}
          />
        ) : (
          <></>
        )}
      </HomeLayout>
    </BaseLayout>
  );
}
