import { BSC, BSCTestnet } from "@usedapp/core";
import { RbaChain } from "./chain";

export const MULTICALL_ADDRESS = {
  [BSC.chainId]: BSC.multicallAddress,
  [BSCTestnet.chainId]: BSCTestnet.multicallAddress,
  [RbaChain.chainId]: RbaChain.multicallAddress,
  158: RbaChain.multicallAddress,
  159: "0xbD165449C0e33c5E3a6d5c50C3381E9DA1e4E72a",
};

export const RPC_ADDRESS = {
  [BSCTestnet.chainId]: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  159: "https://preseed-testnet-1.roburna.com/",
  [BSC.chainId]: "https://rpc.ankr.com/bsc",
  158: "https://dataseed.roburna.com",
};

export const ADMIN_ADDRESS = {
  [BSC.chainId]: BSC.multicallAddress,
  [BSCTestnet.chainId]: "0x1bf08cf44A856fe437Bedc89BA53bF43FE5Ca15D",
  [RbaChain.chainId]: "0x00687AD41983f022929E9e5C9a2cd58A291F661f",
  158: "0x00687AD41983f022929E9e5C9a2cd58A291F661f",
};

export const FACTORY_ADDRESS = {
  [BSC.chainId]: "0x35beffc0713034a91bb0aa45b9bc15c0a7ae24ca",
  [BSCTestnet.chainId]: "0x4e6EaABE9F57C6c757a6Fc3F1e97Cb2d0f05C6B9",
  158: "0xD209fb15c9BF719998292642A08eA551c962D3D0",
};

export const AIRDROP_FACTORY_ADDRESS = {
  [BSC.chainId]: "0x7442c5433e34Ddf0088d60BeFFefB1536d421904",
  [BSCTestnet.chainId]: "0x4F0Fcf99e7BE47a962599b42dcE3412A613D87FF",
  159:"0xEad8a35b9bA9B4fE98761449CD01a0F63e551ddD",
  158:"0x69C197fd50F6c1eC1f98259474450478658e4209",
};
