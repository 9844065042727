import { useState, useEffect } from "react";
import { BSC, BSCTestnet } from "@usedapp/core";
import Web3 from "web3";

export function useDefaultChainId() {
  const [defaultChainId, setDefaultChainId] = useState(null); // Initialize with null or undefined

  useEffect(() => {
    const fetchChainId = async () => {
      try {
        const storedChainId = localStorage.getItem("network-switch");
        if (storedChainId) {
          const items = JSON.parse(storedChainId);
          const activeItem = items.find((item) => item.isActive);

          if (activeItem.title === "BSC Testnet") {
            setDefaultChainId(BSCTestnet.chainId);
          } else if (activeItem.title === "Binance Smart Chain") {
            setDefaultChainId(BSC.chainId);
          } else if (activeItem.title === "Roburna") {
            setDefaultChainId(158);
          } else if (activeItem.title === "Roburna Testnet") {
            setDefaultChainId(159);
          }
        } else {
          const chainId = await getChainId();
          setDefaultChainId(chainId);
        }
      } catch (error) {
        console.error("Error fetching chain ID:", error);
      }
    };

    fetchChainId();

    const handleStorageChange = (e) => {
      const network = localStorage.getItem("network-switch");
      if (network) {
        const items = JSON.parse(network);
        const activeItem = items.find((item) => item.isActive);

        if (activeItem.title === "BSC Testnet") {
          setDefaultChainId(BSCTestnet.chainId);
        } else if (activeItem.title === "Binance Smart Chain") {
          setDefaultChainId(BSC.chainId);
        } else if (activeItem.title === "Roburna") {
          setDefaultChainId(158);
        } else if (activeItem.title === "Roburna Testnet") {
          setDefaultChainId(159);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  async function getChainId() {
    const web3 = new Web3(window.ethereum);
    const chainId = await web3.eth.getChainId();
    return chainId;
  }

  return defaultChainId;
}
