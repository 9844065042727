import React, { useState } from "react";
import PreviewHeader from "../../Common/PreviewHeader";
import BackArrowSVG from "../../../svgs/back_arrow";
import PreviewDetails from "../../Common/PreviewDetails";
import { useModal } from "react-simple-modal-provider";
import { useEthers } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import AirdropFactoryAbi from "config/abi/AirdropFactory.json";
import { formatBigToNum } from "../../../utils/numberFormat";
import StartPublicAirdropCreationPage from "../AirdropPage/Modal/StartPublicAirdropCreationPgae";
import StartPrivateAirdropCreation from "../AirdropPage/Modal/StartPrivateAirdropCreation";
import AddAllocationsCreation from "./AddAllocationsCreationPage";
import { AIRDROP_FACTORY_ADDRESS } from "config/constants/address";
import axios from "axios";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import { useDefaultChainId } from "config/useDefaultChainId";
import { utils } from "ethers";
export default function Createsale({
  setAirdropData,
  airdropData,
  token,
  setActive,
  amount,
}) {
  const { library ,account} = useEthers();
  console.log(library,"library")
  const [modal, showModal] = useState(0);
  const [error, setError] = useState();
  const chainId = useDefaultChainId();
  // console.log(defaultChainId, "defaultChainId")
  

  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");
console.log(airdropData, "airdropData")
  const handleCreateAirdrop = async () => {
    openLoadingModal();
    if (!account) {
      closeLoadingModal();
      alert("Please connect your wallet");
      return;
    }
    const contract = new Contract(
      AIRDROP_FACTORY_ADDRESS[chainId],
      AirdropFactoryAbi,
      library.getSigner()
    );
    const fee = await contract.fee();
    console.log(fee, "fee")
    try {
      console.log("airdropData")
      const createAirdrop = await contract.deployAirdrop(
        airdropData.tokenAddress,
        [
          airdropData.image,
          airdropData.description,
          airdropData.tags,
          airdropData.website,
          airdropData.twitter,
          airdropData.linkedin,
          airdropData.github,
          airdropData.name,
        ],
        {
          value: fee.privateFee,
        }
      );
      await createAirdrop.wait();
      const airdropAddress = await contract.getLastDeployedAirdrop();
      const updatedAirdropData = {
        ...airdropData,
        airdropAddress: airdropAddress,
      };
    
      setAirdropData(updatedAirdropData);
      setError(undefined);
      try {
        const res = await axios.post(
          `${BACKEND_URL}/api/airdrop/`,
          {
            airdrop: updatedAirdropData,
            chainId: chainId,
          },
          {
            withCredentials: true,
          }
        );


      }
      catch (error) {
        console.log(error);
      }
      closeLoadingModal();
      showModal(2);
      return;
    } catch (error) {
      console.log("Create Airdrop Error")
      console.log(error);
      setError(error.reason);
      closeLoadingModal();
      return false;
    }
  };

  const handleCreatePublicAirdrop = async () => {
    openLoadingModal();
    console.log(AIRDROP_FACTORY_ADDRESS[chainId], "AIRDROP_FACTORY_ADDRESS[chainId]")
    const contract = new Contract(
      AIRDROP_FACTORY_ADDRESS[chainId],
      AirdropFactoryAbi,
      library.getSigner()
    );
    const fee = await contract.fee();

    console.log(fee.publicFee, "fee")
    console.log(formatBigToNum(fee.publicFee, 18))
    // const publicFee = fee
    console.log(airdropData.tokenAddress, "airdropData.tokenAddress")
    // second array
    console.log([
      airdropData.image,
      airdropData.description,
      airdropData.tags,
      airdropData.website,
      airdropData.twitter,
      airdropData.linkedin,
      airdropData.github,
      airdropData.name,
    ], "second array")

    try {
      const createAirdrop = await contract.deployPublicAirdrop(
        airdropData.tokenAddress,
        [
          airdropData.image,
          airdropData.description,
          airdropData.tags,
          airdropData.website,
          airdropData.twitter,
          airdropData.linkedin,
          airdropData.github,
          airdropData.name,
        ],
        {
          value: fee.publicFee,
        }
      );

      await createAirdrop.wait();
      const airdropAddress = await contract.getLastDeployedAirdrop();
      const updatedAirdropData = {
        ...airdropData,
        airdropAddress: airdropAddress,
      };
    
      setAirdropData(updatedAirdropData);
      setError(undefined);
      try {
        const res = await axios.post(
          `${BACKEND_URL}/api/airdrop/`,
          {
            airdrop: updatedAirdropData,
            chainId: chainId,
          },
          {
            withCredentials: true,
          }
        );


      }
      catch (error) {
        console.log(error);
      }
      closeLoadingModal();
      showModal(4);
      return;
    } catch (error) {
      console.log(error, "error")
      console.log("Create Public Airdrop Error")
      if(error.data.message){
        setError("Insufficient balance. Required 25000")
      }
      closeLoadingModal();
      return false;
    }
  };

  return (
    <div className="">
      {modal !== 0 && (
        <div className="fixed backdrop-blur-[7px] w-full h-full flex justify-center  z-50  top-0 left-0">
          <div className="h-screen sticky top-0 w-full flex items-center">
            {modal === 2 && (
              <AddAllocationsCreation
              airdrops = {airdropData}
                decimals={airdropData.tokenDecimals}
                airdropAddress={airdropData.airdropAddress}
                tokenAddress={airdropData.tokenAddress}
                showModal={showModal}
                modal={modal}
              />
            )}
            {modal === 3 && (
              <StartPrivateAirdropCreation
                decimals={airdropData.tokenDecimals}
                airdropAddress={airdropData.airdropAddress}
                tokenAddress={airdropData.tokenAddress}
                showModal={showModal}
                modal={modal}
              />
            )}
            {modal === 4 && (
              <StartPublicAirdropCreationPage
                decimals={airdropData.tokenDecimals}
                tokenAddress={airdropData.tokenAddress}
                airdropAddress={airdropData.airdropAddress}
                showModal={showModal}
                modal={modal}
              />
            )}
          </div>
        </div>
      )}
      {/* <div className="flex items-center">
        <img src={token.icon} alt={token.name} className="w-[54px] h-[54px]" />

        <div className=" ml-4">
          <div className="flex items-center">
            <h3 className=" font-bold dark:text-light-text">{token.name}</h3>
          </div>

          <div className="flex items-center mt-2">
            {token.tags.map((tag) => (
              <div
                key={tag.id}
                className="bg-[#F5F1EB] dark:bg-dark-3 mr-[6px] py-[2px] px-[10px] rounded text-xs text-gray dark:text-gray-dark font-medium"
              >
                {tag.name}
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <PreviewHeader heading={"Airdrop Info"} />

      <PreviewDetails name="Description" value={airdropData.description} />
      <PreviewDetails name="Tags" value={airdropData.tags} />
      <PreviewDetails name="Website" value={airdropData.website} />
      <PreviewDetails name="Twitter" value={airdropData.twitter} />
      <PreviewDetails name="Linkedin" value={airdropData.linkedin} />
      <PreviewDetails name="Github" value={airdropData.github} />

      <PreviewHeader heading={"Token address Details"} />

      <PreviewDetails name="Name" value={airdropData.tokenName} />
      <PreviewDetails name="Symbol" value={airdropData.tokenSymbol} />
      <PreviewDetails name="Decimals" value={airdropData.tokenDecimals} />
      <PreviewDetails
        name="Total Supply"
        value={`${formatBigToNum(
          airdropData.tokenSupply,
          airdropData.tokenDecimals
        )} ${airdropData.tokenSymbol}`}
      />

      <div className="mt-10">
        <div className="flex justify-end items-center mb-10">
          <button
            className="bg-white dark:bg-transparent mr-5 flex items-center gap-2 py-[10px] px-5"
            onClick={() => setActive("Project Details")}
          >
            <BackArrowSVG className="fill-dark-text dark:fill-light-text" />
            <span className="font-gilroy font-medium text-sm text-dark-text dark:text-light-text">
              Go Back
            </span>
          </button>

          <button
            className="bg-primary-green disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
            // disabled={address.length < 5}
            onClick={
              airdropData.type === "private"
                ? handleCreateAirdrop
                : handleCreatePublicAirdrop
            }
          >
            Create Airdrop
          </button>
          
        </div>
      </div>
      {error && (
            <p className="mt-4 text-red-500 text-center">
              {error.replace(/\b\w/g, (c) => c.toUpperCase())}
            </p>
          )}
    </div>
  );
}
