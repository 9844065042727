import * as React from 'react'

const SheildSecuritySVG = (props) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.4}
      d="M9.133 1.758 4.55 3.475c-.875.333-1.592 1.366-1.592 2.308v6.75c0 .675.442 1.567.983 1.967l4.584 3.425c.808.608 2.133.608 2.941 0L16.05 14.5c.541-.409.983-1.292.983-1.967v-6.75c0-.933-.717-1.975-1.592-2.3l-4.583-1.717c-.467-.183-1.25-.183-1.725-.008Z"
    />
    <path d="M12.084 8.75a2.084 2.084 0 0 0-4.167 0c0 .934.617 1.709 1.458 1.975v2.192a.63.63 0 0 0 .625.625.63.63 0 0 0 .625-.625v-2.192a2.073 2.073 0 0 0 1.459-1.975Z" />
  </svg>
)

export default SheildSecuritySVG
