import React from "react";
import ModalField from "./ModalField";
import { useEffect, useState, useRef } from "react";
import { BigNumber, Contract, ethers } from "ethers";
import { useEtherBalance, useEthers } from "@usedapp/core";
import PublicSaleAbi from "../../../config/abi/PublicSale.json";
import PublicSaleErcAbi from "../../../config/abi/PublicSaleErcAbi.json";
import PrivateSaleAbi from "../../../config/abi/PrivateSale.json";
import PrivateSaleErcAbi from "../../../config/abi/PrivateSaleErcAbi.json";
import FairLaunchAbi from "../../../config/abi/FairlaunchSale.json";
import FairLaunchErcAbi from "../../../config/abi/FairlaunchErcAbi.json";
import { formatEther, parseEther } from "ethers/lib/utils";
import axios from "axios";
import getSaleInfo from "utils/getSaleInfo";
import usePublicErcSaleInfo from "utils/getPublicErcSaleInfo";
import usePrivateSaleInfo from "utils/getPrivateSaleInfo";
import usePrivateErcSaleInfo from "utils/getPrivateErcSaleInfo";
import useFairlaunchSaleInfo from "utils/getFairLaunchSaleInfo";
import useFairlaunchErcSaleInfo from "utils/getFairLaunchErcSaleInfo";
import { formatBigToNum } from "utils/numberFormat";
import ERC20 from "config/abi/ERC20.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getAmountParticipated from "utils/getAmountParticipated";
import Web3 from "web3";
import { useModal } from "react-simple-modal-provider";
import {
  BACKEND_URL,
  BACKEND_URL_REF,
} from "config/constants/LaunchpadAddress";
import { useDefaultChainId } from "config/useDefaultChainId";
import { send } from "process";
export default function Modal({
  showModal,
  from_symbol,
  from_icon,
  to_icon,
  to_symbol,
  sale,
  objId,
  // account,
}) {
  const { library } = useEthers();
  const [amount, setAmount] = useState(0);
  const [bnbUSD, setBnbUSD] = useState(317);
  const [usdAmount, setUsdAmount] = useState(sale.minAllocation * bnbUSD);
  const [tokenPrice, setTokenPrice] = useState(parseFloat(sale.presalePrice));
  const [saleInfo, setSaleInfo] = useState(null);
  const [isHandled, setIsHandled] = useState(false);
  const buttonRef = useRef(null);

  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");

  useEffect(() => {
    const result = getSaleInfo(sale.saleAddress, sale.saleType).then((res) => {
      setSaleInfo(res);
    });
  }, []);

  //set chain
  const chainId = useDefaultChainId();
  // const sale_info_public_erc = usePublicErcSaleInfo(sale.saleAddress);
  let account = "";
  const [balanceBNB, setBalanceBNB] = useState(null);
  const [balance, setBalance] = useState(0);
  const [acct, setAcct] = useState("");
  let bought = "";

  useEffect(() => {
    async function connectWallet() {
      if (typeof window.ethereum !== "undefined") {
        // Instance web3 with the provided information
        const web3 = new Web3(window.ethereum);
        try {
          // Request account access
          await window.ethereum.enable();
          // Wallet connected successfully
          // You can perform further actions here
          account = await web3.eth.getAccounts();
          setAcct(account[0]);
          web3.eth.getBalance(account[0]).then((res) => {
            setBalanceBNB(res);
          });
        } catch (e) {
          // User denied access or error occurred
          // Handle the error or show appropriate message
        }
      }
    }

    connectWallet();
  }, []);

  const [tokenAmount, setTokenAmount] = useState(0);

  // useEffect(() => {
  //   const result = getSaleInfo(sale.saleAddress).then((res) => {
  //     setSaleInfoPublic(res);
  //   });
  // }, []);

  useEffect(() => {
    console.log("currenct address", sale.currency.address);
    console.log(acct, "acct");
    if (
      (sale.currency.symbol !== "BNB" && chainId === 56) ||
      (chainId === 158 && sale.currency.symbol !== "RBA")
    ) {
      console.log(sale.currency.address, "sale.currency.address");
      const contract = new Contract(
        sale.currency.address,
        ERC20,
        library.getSigner()
      );
      const getBalance = async () => {
        try {
          if (!acct) return;
          const balance = await contract.balanceOf(acct);
          const balanceString = formatBigToNum(balance, 18);
          setBalance(parseFloat(balanceString.replace(/,/g, "")));
        } catch (e) {
          console.log(e);
        }
      };
      getBalance();
    } else {
      if (!balanceBNB) return;
      setBalance(formatEther(balanceBNB).substring(0, 6));
    }
  }, [balanceBNB, acct]);

  // async function getPrice() {
  //   if (!saleInfoPublic) return;
  //   const res = await saleInfoPublic.totalBNBRaised;
  //   setPriceInBNB(res);
  // }
  // useEffect(() => {
  //   getPrice();
  // }, [saleInfoPublic]);

  // useEffect(() => {
  //   if (priceInBNB === null) return;
  //   if (
  //     saleInfoPublic
  //     // sale_info_public_erc &&
  //     // sale_info_private &&
  //     // sale_info_private_erc &&
  //     // sale_info_fairlaunch &&
  //     // sale_info_fairlaunch_erc
  //   ) {
  //     if (sale.currency.symbol === "BNB") {
  //       if (sale.saleType === "standard") {
  //         console.log(priceInBNB)
  //         const price = formatBigToNum(priceInBNB?.toString(), 18, 4);
  //         setTokenPrice(price);
  //       }
  //       // else if (sale.saleType === "private") {
  //       //   console.log("sale_info_private", sale_info_private)
  //       //   const price = formatBigToNum(
  //       //     sale_info_private.tokenPriceInBNB.toString(),
  //       //     18,
  //       //     4
  //       //   );
  //       //   setTokenPrice(price);
  //       // }
  //       // else if (sale.saleType === "fairlaunch") {
  //       //   const price = formatBigToNum(
  //       //     sale_info_fairlaunch.tokenPriceInBNB.toString(),
  //       //     18,
  //       //     4
  //       //   );
  //       //   setTokenPrice(price);
  //       // }
  //     } else {
  //       if (sale.saleType === "standard") {
  //         // console.log(
  //         //   "sale_info_public_erc",
  //         //   sale_info_public_erc.tokenPriceInERC20
  //         // );
  //         // const price = formatBigToNum(
  //         //   sale_info_public_erc.tokenPriceInERC20.toString(),
  //         //   18,
  //         //   4
  //         // );
  //         // setTokenPrice(price);
  //         toast.error("Please buy with BNB");
  //       }
  //       // else if (sale.saleType === "private") {
  //       //   const price = formatBigToNum(
  //       //     sale_info_private_erc.tokenPriceInERC20.toString(),
  //       //     18,
  //       //     4
  //       //   );
  //       //   setTokenPrice(price);
  //       // }
  //       // else if (sale.saleType === "fairlaunch") {
  //       //   const price = formatBigToNum(
  //       //     sale_info_fairlaunch_erc.tokenPriceInERC20.toString(),
  //       //     18,
  //       //     4
  //       //   );
  //       //   setTokenPrice(price);
  //       // }
  //     }
  //   }
  // }, [priceInBNB, saleInfoPublic]);

  const convertBNBtoUSD = async () => {
    try {
      const res = await axios.get(`${process.env.API_URL}`, {
        headers: {
          Accepts: "application/json",
          "X-CMC_PRO_API_KEY": process.env.API_KEY,
        },
        params: { slug: "bnb", convert: "USD" },
      });

      //console.log("res",res)
    } catch {
      setBnbUSD(317);
    }
  };

  useEffect(() => {
    convertBNBtoUSD();
  }, []);

  //get user balanceBNB
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    //user balanceBNB
    //check if sale started
    if (!isHandled) {
      setIsHandled(true);
      bought = await getAmountParticipated(
        sale.saleAddress,
        acct,
        sale.saleType
      );
      console.log("bought", bought);
      const userAllocation = formatBigToNum(bought[0].toString(), 19, 4);

      if (parseFloat(userAllocation) > parseFloat(sale.maxAllocation)) {
        console.log(
          "userAllocation",
          userAllocation,
          "sale.maxAllocation",
          sale.maxAllocation
        );
        toast.error("You have reached the maximum allocation");
        return;
      }
      // console.log(sale)
      const start = new Date(sale.startDate);
      const now = new Date();
      // console.log("start", start, "now", now);
      if (now < start) {
        toast.error("Sale not started yet");
        return;
      }

      const saleContractAddress = sale.saleAddress;

      let abi;
      let network;
      let saleType;
      if (chainId === 56) {
        network = "bsc_mainnet";
      } else if (chainId === 158) {
        network = "rba_mainnet";
      } else {
        network = "bsc_testnet";
      }
      if (
        (sale.currency.symbol === "BNB" && chainId === 56) ||
        (chainId === 158 && sale.currency.symbol === "RBA")
      ) {
        if (sale.saleType === "standard") {
          abi = PublicSaleAbi;
          saleType = "Public";
        } else if (sale.saleType === "private") {
          abi = PrivateSaleAbi;
          saleType = "Private";
        } else {
          abi = FairLaunchAbi;
        }
      } else {
        if (sale.saleType === "standard") {
          abi = PublicSaleErcAbi;
          saleType = "PublicErc";
        } else if (sale.saleType === "private") {
          abi = PrivateSaleErcAbi;
          saleType = "PrivateErc";
        } else {
          abi = FairLaunchErcAbi;
        }
      }

      const contract = new Contract(
        saleContractAddress,
        abi,
        library.getSigner()
      );
      // console.log("contract", contract);
      const amountBuy = parseEther(amount.toString()).toString();
      openLoadingModal();
      try {
        if (
          (sale.currency.symbol !== "BNB" && chainId === 56) ||
          (chainId === 158 && sale.currency.symbol !== "RBA")
        ) {
          const approvalContract = new Contract(
            sale.currency.address,
            ERC20,
            library.getSigner()
          );
          try {
            const approval = await approvalContract.approve(
              sale.saleAddress,
              ethers.constants.MaxUint256
            );
            await approval.wait();
          } catch (err) {
            console.log(err);
          }
        }

        if (
          (sale.currency.symbol === "BNB" && chainId === 56) ||
          (chainId === 158 && sale.currency.symbol === "RBA")
        ) {
          const tx = await contract.participate({
            value: amountBuy,
          });
          await tx.wait();
          console.log(tx, "tx is here");
          const amt = formatEther(tx.value._hex);
          console.log(amt, "amt");
          sendTransactionToBackend(tx.hash, network, saleType, amt);
        } else {
          console.log("acct", acct, amountBuy);
          const tx = await contract.participate(amountBuy);
          await tx.wait();
          console.log(tx, "Tx is here");
          const amt = formatEther(tx.value._hex);
          sendTransactionToBackend(tx.hash, network, saleType, amt);
        }
        try {
          const result = await getSaleInfo(sale.saleAddress, sale.saleType);
          let res;
          if (
            (sale.currency.symbol === "BNB" && chainId === 56) ||
            (sale.currency.symbol === "RBA" && chainId === 158)
          ) {
            res = await result.totalBNBRaised;
          } else {
            res = await result.totalERC20Raised;
          }
          res = BigNumber.from(res);
          const percents = res.mul(100).div(await result.hardCap);
          const filled = formatBigToNum(percents.toString(), 0, 1);
          console.log(filled);
          const finalSaleObject = { ...sale, filledPercent: filled };
          console.log(finalSaleObject, "finalSaleObject");
          const res2 = await axios.put(`${BACKEND_URL}/api/sale/${objId}`, {
            sale: finalSaleObject,
          });
          console.log(res2, "res2");
        } catch (err) {
          console.log(err);
        }
        toast.success("Transaction successful");
        window.location.reload();
        showModal(false);
      } catch (err) {
        toast.error("Transaction failed");
        console.log(err);
      }
      closeLoadingModal();
      setTimeout(() => {
        setIsHandled(false);
      }, 300);
    }
  };
  const sendTransactionToBackend = async (hash, network, saleType, amount) => {
    let referralId;
    let saleId;
    try {
      referralId = document.cookie.split("referralId=")[1].split(";")[0];
      saleId = document.cookie.split("saleId=")[1].split(";")[0];
      // if sale id doesnt match set referralId to 0
      if (saleId !== sale.saleAddress) {
        referralId = "0";
      }
    } catch (err) {
      console.log(err);
      referralId = "0";
    }

    const url = `${BACKEND_URL_REF}/transactions?hash=${hash}&referralId=${referralId}&network=${network}&saleType=${saleType}&amount=${String(
      amount
    )}&wallet=${acct}&percentage=${
      sale.referralPercentage ? sale.referralPercentage : 0
    }`;
    console.log("sending transaction to backend", url);
    try {
      const res = await axios.post(url);
      console.log(res, "res of transaction");
      // if cookie exists, delete it
    } catch (err) {
      console.log(err);
    }
  };

  const handleInput = async (e) => {
    setAmount(Number(e.target.value));
    setUsdAmount((Number(e.target.value) * bnbUSD).toFixed(3));
    setTokenAmount((Number(e.target.value) * tokenPrice).toFixed(5));
  };

  const handleMax = () => {
    //balanceBNB to number everything after , is not removed
    if (balanceBNB === null) {
      toast.error("Connect wallet first");
      return;
    }
    //if balance is less than max allocation show error
    // console.log(parseparseFloat((saleInfo?.hardCap.toString() - saleInfo?.totalBNBRaised.toString()).toString()).toString());
    console.log(
      parseFloat(balance),
      formatBigToNum(
        parseFloat(
          saleInfo?.hardCap.toString() - saleInfo?.totalBNBRaised.toString()
        ).toString(),
        18
      )
    );
    const remaining = parseFloat(
      formatBigToNum(
        parseFloat(
          saleInfo?.hardCap.toString() - saleInfo?.totalBNBRaised.toString()
        ).toString(),
        18
      )
    );
    // if (
    //   parseFloat(balance) <
    //   parseFloat(remaining)
    // ) {
    //   // console.log(balance, "balance", "required:", parseEther(saleInfo?.hardCap.toString() - saleInfo?.totalBNBRaised.toString() ));
    //   toast.error("Insufficient balance");
    //   return;
    // }
    let bal = balance;
    let amt;
    if (remaining < sale.maxAllocation && remaining > sale.minAllocation) {
      amt = remaining;
    } else {
      amt = sale.maxAllocation;
    }
    setAmount(amt);
    console.log(amt, "amt");
    setUsdAmount((amt * bnbUSD).toFixed(3));
    console.log(amt * tokenPrice);
    setTokenAmount((amt * tokenPrice).toFixed(5));
  };
  const handleHalf = () => {
    if (balanceBNB === null) {
      toast.error("Connect wallet first");
      return;
    }
    //if balance is less than max allocation show error
    const remaining = parseFloat(
      formatBigToNum(
        parseFloat(
          saleInfo?.hardCap.toString() - saleInfo?.totalBNBRaised.toString()
        ).toString(),
        18
      )
    );
    // if (
    //   parseFloat(balance) <
    //   parseFloat(remaining/2)
    // ) {
    //   toast.error("Insufficient balance");
    //   return;
    // }
    let bal = balance;
    let amt;
    if (
      remaining / 2 < sale.maxAllocation &&
      remaining / 2 > sale.minAllocation
    ) {
      amt = remaining;
    } else {
      amt = sale.maxAllocation;
    }
    setAmount(amt / 2);
    setUsdAmount(((amt / 2) * bnbUSD).toFixed(3));
    setTokenAmount(((amt / 2) * tokenPrice).toFixed(5));
  };

  const handleTouchStart = (event) => {
    if (event.target === buttonRef.current) {
      handleSubmit(event);
    }
  };

  const handleClick = (event) => {
    if (event.target === buttonRef.current) {
      handleSubmit(event);
    }
  };

  return (
    <>
      <div
        className={`w-screen h-screen flex backdrop-blur-[7px] flex-col justify-center items-center bg-[#F2F3F5] dark:bg-dark dark:bg-opacity-40 bg-opacity-40`}
      >
        <div className="w-[90%] max-w-[420px] rounded-[10px] px-9 py-5 bg-white dark:bg-dark-1">
          <div className="flex justify-between items-center  ">
            <span className="text-dark-text dark:text-light-text font-gilroy font-semibold text-lg">
              Join Pool
            </span>

            <div
              className="flex items-center cursor-pointer"
              onClick={() => showModal(false)}
            >
              <span className="text-sm font-gilroy font-semibold text-dark-text dark:text-light-text mr-2">
                Close
              </span>
              <div className="flex justify-center items-center bg-[#E56060] text-[#E56060] bg-opacity-10 rounded-full w-[15px] h-[15px]">
                &#10005;
              </div>
            </div>
          </div>
          <div className="mt-[30px]">
            <ModalField text={"From"} icon={from_icon} currency={from_symbol} />
          </div>

          <div className="mt-7">
            <div className="flex justify-between items-center">
              <span className="font-semibold text-sm text-gray dark:text-gray-dark">
                Amount
              </span>

              <span className="font-medium text-sm text-dim-text dark:text-dim-text-dark">
                Balance:
                <span className="text-dark-text dark:text-light-text">
                  {balance && balance.toLocaleString()}
                </span>
              </span>
            </div>
          </div>
          <div className="mt-[10px] flex justify-between items-center rounded-md bg-[#F5F1EB] dark:bg-dark-3 px-5 py-5">
            <div className="flex flex-col">
              <input
                className="bg-transparent outline-none text-sm font-medium text-dark-text dark:text-light-text"
                type="number"
                placeholder="0.0"
                onChange={handleInput}
                value={amount}
                step={0.001}
                max={sale.maxAllocation}
                min={sale.minAllocation}
              />

              <span className="mt-3 text-sm font-medium text-gray dark:text-gray-dark">
                ~ ${usdAmount}
              </span>
            </div>

            <div className="border-l border-dashed pl-5 border-dim-text dark:border-dim-text-dark ">
              <button
                onClick={handleHalf}
                className="rounded-sm bg-[#C29D46] bg-opacity-[0.08] py-[2px] px-4 text-[#C89211] text-sm onhover:cursor-pointer"
              >
                Half
              </button>

              <div
                onClick={handleMax}
                className="mt-3 rounded-sm bg-primary-green bg-opacity-[0.08] py-[2px] px-4 text-primary-green text-sm"
              >
                Max
              </div>
            </div>
          </div>

          <div className="flex justify-center my-7">
            <img src="/images/arrows/arrow_down_green.svg" alt="arrow down" />
          </div>

          <div className="mt-4">
            <ModalField
              text={"You'll get"}
              icon={to_icon}
              currency={to_symbol}
            />
          </div>

          <div className="mt-7">
            <span className="font-semibold text-sm text-gray dark:text-gray-dark">
              Amount
            </span>
          </div>

          <div className="mt-[10px]  rounded-md bg-[#F5F1EB] dark:bg-dark-3 px-5 py-5">
            <div className="flex justify-between items-center">
              <span className="font-bold text-xl text-dark-text dark:text-light-text">
                {parseFloat(tokenAmount)}
              </span>
              <span className="text-sm font-medium text-gray dark:text-gray-dark">
                ~ $---
              </span>
            </div>
          </div>
        </div>

        <div className="w-[90%] max-w-[420px]  mt-1">
          <button
            className="w-full bg-primary-green text-white py-5 rounded-md font-gilroy font-bold text-xl disabled:opacity-50"
            ref={buttonRef}
            onClick={handleClick}
            onTouchStart={handleTouchStart}
            disabled={
              parseFloat(amount) > parseFloat(balance) ||
              parseFloat(amount) === 0
            }
          >
            Buy
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
